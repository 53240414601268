import React from 'react'
import { Link } from 'gatsby'
import ErrorTemplate from '../templates/error'
import RichText from '../components/rich-text'

const NotFoundPage = () => {
  const title = 'Page Not found'
  return (
    <ErrorTemplate heading={title}>
      <RichText>
        <p>
          If you entered a web address please check it was correct, <br />
          or <Link to="/">click here</Link> to go to the homepage.
        </p>
      </RichText>
    </ErrorTemplate>
  )
}

export default NotFoundPage
